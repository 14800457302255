
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Secular+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Old+Standard+TT&display=swap'); */

* {
    font-family: TimesNewRoman,Times New Roman,Times,Baskerville,Georgia,serif; 
}
h1{
    font-weight: 300 !important;
}
p{
    font-size: 20px ;
}

.p_name{
    font-size: 18px ;
    font-family: 'Old Standard TT', serif;
}

.pp-a {
    padding: 0 20px;
}

.p-small {
    font-size: 19px !important;
}

.img-sm {
    width: 90%;
}

section {
    .carousel {
        height: 600px;

        .carousel-inner {
            width: 100%;
            background: #ebedec;
            height: 100%;

            .item2 {
                background-image: url('../../public/assets/img/bg/bg1.jpg');
            }

            .item1 {
                background-image: url('../../public/assets/img/bg/bg2.jpg');
            }

            .carousel-item {
                height: 100%;
                width: 100%;
                background-repeat: no-repeat;
                background-size: cover;

                .item {
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 18.82%, #000 90.88%);
                    font-family: 'Droid Sans', 'Chilanka';

                    p {
                        font-weight: bold;
                    }
                }
            }
        }
    }

}

// .sponsored {
//     width: 90%;
//     margin: auto;
//     overflow: hidden;

//     .swiper-wrapper {
//         display: flex;
//     }
// }

#review {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .swiper-wrapper {
        display: flex;

        .swiper-slide {
            flex-shrink: 0;
            width: 100% !important;
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        opacity: 0.3;

        &:hover {
            opacity: 1;
        }
    }
}

.invest_price {
    width: 100%;

    .heading {
        color: white;
        text-align: center;
        background: rgb(192, 148, 2);
        border-radius: 0 0 50% 50%;
    }

    .underline-bottom {
        border-bottom: 1px solid rgb(94, 94, 94);
    }
}

.about-home {
    padding: 20px;

    p {
        font-size: 23px !important;
    }
}

@media (max-width:576px) {
    .sm_pb_none{
        padding-bottom: 0 !important;
    }
    .img-sm {
        width: 100%;
    }

    .sponsored {
        img {
            width: 100%;
        }
    }

    .pp-a {
        padding: 0;
    }

    .about-home-reverse {
        flex-direction: column-reverse;
    }

    .about-home {
        padding: 20px 25px;

        img {
            margin-bottom: 15px;
        }

        p {
            font-size: 20px !important;
        }
    }

    .carousel {
        .item2 {
            background-image: url('https://goldco.com/wp-content/uploads/2023/02/Sean-Hannity-Website-Homepage-MOBILE-780x764-2.jpg') !important;
            background-position: center !important;
        }

        .item1 {
            background-image: url('../../public/assets/img/bg/sm-bg.jpeg') !important;
            background-position: left !important;
        }

        .item {
            padding: 0 20px;

            h1 {
                font-size: 30px !important;
                line-height: 36px !important;
            }
        }
    }

    .inv {
        h1 {
            font-size: 36px !important;
            padding: 0 20px;
        }
    }
}