footer{
    background: #222226;
    p{
        color:#858282;
        font-size: 15px !important;
        text-align: justify;
    }
    nav{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        // padding:20px;
        .footer-link{

            ul{
                margin-bottom: 0;
                padding:0;
                display: flex;
                justify-content: center;
                list-style: none;

                a{
                    text-decoration: none;
                    color:white;
                    display: inline-block;
                    padding:6px;
                    margin:0 5px;
                }
            }
        }
    }

    .social-media-link{
        a{
            color:white;
            padding:6px;
            margin:0 2px;
        }
    }
    .down-footer-link{
        
        nav{
            justify-content: center;
            a{
                color:#858282 !important;
                font-size: 15px !important;

            }
        }
    }
}
@media (max-width:576px) {
    footer{
        
        nav{
            flex-direction: column;
            .logo{
                margin-bottom: 10px;
            }
            .footer-link{
                margin-bottom:15px;
            }
            ul{
                flex-direction: column;
                li{
                   a{
                    display: block !important;
                    text-align: center;
                   }
                }
            }
        }
    }
}