header {
    display: flex;
    justify-content: space-around;
    background: #f3f3f3;
    padding: 20px 0;
    position: relative;
    align-content: center;

    .logo {
        display: flex;
        align-items: center;
        ;
    }

    nav {
        display:flex;
        align-items:center;
        ul {
            display:flex;
            align-items:center;
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;
            position: relative;

            li {
                display: inline-block;

                &:hover {
                    ul {
                        display: block !important;
                    }
                }

            }

            a {
                text-decoration: none;
                display: inline-block;
                font-family: "Open Sans", Sans-serif;
                font-size: 12px;
                padding: 10px 9px;
                color: #333;
                margin-left: 5px;
                align-items: center;
                font-weight: 600;

                &:hover {
                    border-bottom: 2px solid #e0a400;
                    transform: translateY(-2px);
                    color: #333;

                }
            }

            ul {
                display: none !important;
                background: #f3f3f3;
                position: absolute;
                z-index: 2000;

                li {
                    display: block !important;

                    a {
                        padding: 10px 15px;
                        padding-right: 40px;
                        display: block !important;
                        border-bottom: 1px solid rgb(84, 84, 84) !important;
                        margin: 0 !important;

                        &:hover {
                            background: #fdebb8;
                            transform: translateY(0px);

                        }
                    }

                    &:last-child {
                        a {
                            border-bottom: 0px !important;
                        }
                    }
                }
            }
        }
    }

    .nav-button {
        display:flex;
        align-items:center;
        a {
            font-family: "Open Sans", Sans-serif;
            font-size: 12px;
            margin-left: 7px;
            ;
        }
    }

    .menu-toggle {
        cursor: pointer;
        position: absolute;
        display: none;
        font-size: 25px;
    }
}


@media (max-width:576px) {
    .nav-button {
        display:block !important;
    }
    header {
        display: block;
        padding: 0 10px;
        padding-top: 10px;
        height: 100px;
        transition: 0.5s ease-in-out;
        overflow: hidden;
        &.active{
            height: 300px;
        }
        .logo {
            padding-bottom: 10px;
        }

        nav {
            display:block !important;
            ul {
                display:block !important;
                li {
                    display: block;

                    a {
                        display: block;
                        text-align: center;
                    }
                }
            }
        }

        .nav-button {
            margin-top: 8px;
            text-align: center;
        }

        .menu-toggle {
            top: 20px;
            right: 20px;
            display: block;
        }
    }
}